import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

const useStyles = makeStyles((theme) => ({
	titleRow: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	title: {
		color: '#08080d',
		fontSize: 36,
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
	},
	subTitle: {
		color: '#7e7e80',
		fontSize: 16,
		fontWeight: 500,
		marginTop: theme.spacing(12 / 8),
	},
	deliveryInput: {
		marginTop: theme.spacing(24 / 8),
		width: `calc(100vw / 12 * 4 - ${LARGE_GUTTER_SIZE * 4}px)`,
	},
	buttonsBox: {
		marginTop: theme.spacing(40 / 8),
		marginLeft: `calc(100vw / 12 * 5 - ${LARGE_GUTTER_SIZE * 4}px)`,
	},
	confirmButton: {
		width: `calc(100vw / 12 * 3 - ${LARGE_GUTTER_SIZE * 2}px)`,
		padding: theme.spacing(12 / 8),
	},
	errorMessageBox: {
		marginTop: theme.spacing(24 / 8),
	},
	dateWarningDisclaimer: {
		color: '#000',
	},
	dateWarningDate: {
		textDecoration: 'underline',
	},
}))

export default useStyles
