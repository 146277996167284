import {TFunction} from 'i18next'
import * as Yup from 'yup'

import {FormValidationErrorMessages} from '@utils/types/form-validation-error-messages'

export const readyForPickupFormSchema = (t: TFunction): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		frameNumber: Yup.string().required(t(FormValidationErrorMessages.REQUIRED)),
		keyNumber: Yup.string().required(t(FormValidationErrorMessages.REQUIRED)),
	})
