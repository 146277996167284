import {Box, Typography} from '@material-ui/core'
import classNames from 'classnames'
import {Formik} from 'formik'
import PropTypes from 'prop-types'
import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	CustomButtonComponent as CustomButton,
	CustomTextInputComponent as CustomTextInput,
	ErrorMessageBoxComponent as ErrorMessageBox,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {DownloadIconSvg} from '@svg/index'
import {orderPickupInfoAvailable} from '@templates/BicycleDetail/Cta/bicycle-cta-slice'
import {useDeliveryDocumentDownload} from '@utils/hooks'
import {isEmptyString} from '@utils/string-utils'
import {Bicycle, CommonProps} from '@utils/types'

import {readyForPickupFormSchema} from './ready-for-pickup-form-validation'
import useStyles from './style.hook'

interface ReadyForPickupFormProps extends CommonProps {
	bicycle: Bicycle
}

interface FormValues {
	frameNumber: string
	keyNumber: string
}

const ReadyForPickupForm: React.FC<ReadyForPickupFormProps> = ({bicycle}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const dispatch = useDispatch()
	const downloadDocument = useDeliveryDocumentDownload(bicycle)

	const {loading, error} = useSelector((state: RootState) => state.orderFlow)

	const speedBikeTypeId = 638

	const initialFormValues: FormValues = {
		frameNumber: bicycle.frameNumber || '',
		keyNumber: bicycle.keyNumber || '',
	}

	const submitForm = (values: FormValues): void => {
		dispatch(
			orderPickupInfoAvailable(
				bicycle.bicycleId,
				bicycle.actualDeliveryDate!,
				values.frameNumber,
				values.keyNumber
			)
		)
	}

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={readyForPickupFormSchema(t)}
			onSubmit={submitForm}
		>
			{({values, errors, touched, handleSubmit, handleChange}): ReactNode => (
				<form onSubmit={handleSubmit}>
					<Box className={classes.titleRow}>
						<Typography
							id={'bicycle-detail-pickup-info-form-title'}
							className={classes.title}
							variant={'body1'}
						>
							{t('BicycleDetailCtaAlmostReadyToDeliverTheBicycleTitle')}
						</Typography>
						<CustomButton
							type={'text'}
							text={t('BicycleDetailCtaDeliverBicycleSubTitlePart3')}
							startIcon={<DownloadIconSvg />}
							propsToDelegate={{
								onClick: downloadDocument,
							}}
						/>
					</Box>
					<Typography
						id={'bicycle-detail-pickup-info-form-subtitle1'}
						className={classes.subTitle}
						variant={'body1'}
					>
						{t('BicycleDetailCtaAlmostReadyToDeliverTheBicycleSubTitlePart1')}
					</Typography>
					{error && (
						<ErrorMessageBox
							id="ready-for-pickup-form-error-message-box"
							className={classes.errorMessageBox}
							errorMessage={t(error)}
						/>
					)}
					<Box className={classes.inputContainer}>
						<CustomTextInput
							id={'bicycle-detail-pickup-info-form-frame-number'}
							className={classes.inputBox}
							label={t('BicycleDetailCtaFrameNumberLabel2')}
							error={(errors.frameNumber && touched.frameNumber) as boolean}
							helperText={(touched.frameNumber && errors.frameNumber) as string}
							helperTextId={errors.frameNumber && 'frame-number-helper-text'}
							propsToDelegate={{
								onChange: handleChange,
								value: values.frameNumber,
								name: 'frameNumber',
								disabled:
									bicycle.statusIsSyncing ||
									!isEmptyString(bicycle.frameNumber),
							}}
						/>
						<CustomTextInput
							id={'bicycle-detail-pickup-info-form-key-number'}
							className={classes.inputBox}
							label={t('BicycleDetailCtaKeyNumberLabel2')}
							error={(errors.keyNumber && touched.keyNumber) as boolean}
							helperText={(touched.keyNumber && errors.keyNumber) as string}
							helperTextId={errors.keyNumber && 'key-number-helper-text'}
							propsToDelegate={{
								onChange: handleChange,
								value: values.keyNumber,
								name: 'keyNumber',
								disabled: bicycle.statusIsSyncing,
							}}
						/>
						{bicycle.typeId === speedBikeTypeId && (
							<CustomTextInput
								id={'bicycle-detail-pickup-info-form-number-plate'}
								className={classNames(classes.inputBox, classes.licenseInput)}
								label={t('BicycleDetailCtaLicensePlateLabel2')}
								propsToDelegate={{
									name: 'numberPlate',
									value: bicycle.numberPlate,
									disabled: true,
								}}
							/>
						)}
					</Box>
					<Box className={classes.buttonsBox}>
						<CustomButton
							id={'bicycle-detail-pickup-info-form-submit-button'}
							className={classes.updateStatusButton}
							text={
								bicycle.statusIsSyncing
									? t('DealerOrderFlowSyncInProgress')
									: t('BicycleDetailCtaDeliverBicycleButton')
							}
							propsToDelegate={{
								type: 'submit',
								disabled: loading || bicycle.statusIsSyncing,
							}}
						/>
					</Box>
				</form>
			)}
		</Formik>
	)
}

ReadyForPickupForm.propTypes = {
	bicycle: PropTypes.any.isRequired,
}

export default ReadyForPickupForm
