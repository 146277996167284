import {Box, Typography} from '@material-ui/core'
import {Formik} from 'formik'
import PropTypes from 'prop-types'
import React, {
	ChangeEvent,
	ReactNode,
	useEffect,
	useMemo,
	useState,
} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import CustomTextInput from '@components/CustomTextInput'
import {
	CustomButtonComponent as CustomButton,
	CustomDatePickerComponent as CustomDatePicker,
	ErrorMessageBoxComponent as ErrorMessageBox,
	FileUploadAltComponent as FileUploadAlt,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {DownloadIconSvg} from '@svg/index'
import {
	retrieveDeliveryUploadUrls,
	uploadDeliveryDocuments,
} from '@templates/BicycleDetail/Cta/bicycle-cta-slice'
import {endOfToday} from '@utils/date'
import {useDeliveryDocumentDownload} from '@utils/hooks'
import {isEmptyString} from '@utils/string-utils'
import {Bicycle, CommonProps} from '@utils/types'
import {FileType} from '@utils/types/file-types'

import {DeliverFormSchema} from './deliver-form-validation'
import useStyles from './style.hook'

interface FormValues {
	velopassId: string
}

interface DeliverFormProps extends CommonProps {
	bicycle: Bicycle
}

const DeliverForm: React.FC<DeliverFormProps> = ({bicycle}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const dispatch = useDispatch()
	const downloadDocument = useDeliveryDocumentDownload(bicycle)

	const [uploadedDocumentFile, setUploadedDocumentFile] = useState<File>(
		undefined as any
	)
	const [uploadedInvoiceFile, setUploadedInvoiceFile] = useState<File>(
		undefined as any
	)
	const [pickupDate, setPickupDate] = useState<Date>(null as any)
	const maxDate = useMemo(() => endOfToday(), [])

	const {error, upload, loading, success} = useSelector(
		(state: RootState) => state.orderFlow
	)

	const initialFormValues: FormValues = {
		velopassId: bicycle.velopassId || '',
	}

	const submit = (values: FormValues): void => {
		dispatch(
			uploadDeliveryDocuments(
				bicycle.bicycleId,
				upload,
				uploadedDocumentFile,
				uploadedInvoiceFile,
				pickupDate,
				values.velopassId
			)
		)
	}

	const handleChangeDocumentFile = (e: ChangeEvent<HTMLInputElement>): void => {
		const file = e.target.files![0]
		setUploadedDocumentFile(file)
	}

	const handleFileDocumentDelete = (): void => {
		setUploadedDocumentFile(undefined as any)
	}

	const handleChangeInvoiceFile = (e: ChangeEvent<HTMLInputElement>): void => {
		const file = e.target.files![0]
		setUploadedInvoiceFile(file)
	}

	const handleFileInvoiceDelete = (): void => {
		setUploadedInvoiceFile(undefined as any)
	}

	/**
	 * Retrieve document upload data on page load
	 */
	useEffect(() => {
		dispatch(retrieveDeliveryUploadUrls(bicycle.bicycleId))
	}, [])

	const SuccessMessage: React.FC = () => (
		<>
			<Typography
				id={'bicycle-detail-cta-delivery-success-title'}
				className={classes.title}
				variant={'body1'}
			>
				{t('BicycleDetailCtaDeliverySuccessTitle')}
			</Typography>
			<Typography
				id={'bicycle-detail-cta-delivery-success-subtitle1'}
				className={classes.subTitle}
				variant={'body1'}
			>
				{t('BicycleDetailCtaDeliverySuccessSubTitle1')}
			</Typography>
			<Typography
				id={'bicycle-detail-cta-delivery-success-subtitle2'}
				className={classes.subTitle}
				variant={'body1'}
			>
				{t('BicycleDetailCtaDeliverySuccessSubTitle2')}
			</Typography>
		</>
	)

	return (
		<>
			{success ? (
				<SuccessMessage />
			) : (
				<Formik
					initialValues={initialFormValues}
					validationSchema={DeliverFormSchema(t)}
					onSubmit={submit}
				>
					{({
						values,
						errors,
						touched,
						handleSubmit,
						handleChange,
					}): ReactNode => (
						<form onSubmit={handleSubmit}>
							<Box>
								<Box className={classes.titleRow}>
									<Typography
										id={'bicycle-detail-cta-deliver-form-title'}
										className={classes.title}
										variant={'body1'}
									>
										{t('BicycleDetailCtaDeliverBicycleTitle')}
									</Typography>
									<CustomButton
										type={'text'}
										text={t('BicycleDetailCtaDeliverBicycleSubTitlePart3')}
										startIcon={<DownloadIconSvg />}
										propsToDelegate={{
											onClick: downloadDocument,
										}}
									/>
								</Box>
								<Typography
									id={'bicycle-detail-cta-deliver-form-subtitle1'}
									className={classes.subTitle}
									variant={'body1'}
								>
									{t('BicycleDetailCtaDeliverBicycleSubTitlePart1')}
								</Typography>
								{error && (
									<ErrorMessageBox
										id="deliver-form-error-message-box"
										className={classes.errorMessageBox}
										errorMessage={t(error)}
									/>
								)}
								<Box className={classes.topInputContainer}>
									<CustomDatePicker
										id={'bicycle-detail-cta-ordered-form-delivery-date'}
										className={classes.pickupInput}
										label={t('BicycleDetailPickupDateLabel')}
										propsToDelegate={{
											name: 'pickupDate',
											value: pickupDate,
											disabled: bicycle.statusIsSyncing,
											emptyLabel: t('NoPickupDateGiven'),
											maxDate: maxDate,
											onChange: (value): void => setPickupDate(value!),
										}}
									/>
									<CustomTextInput
										id={'bicycle-detail-deliver-form-velopass-id'}
										className={classes.inputBox}
										label={t('BicycleDetailVelopassIdLabel')}
										error={(errors.velopassId && touched.velopassId) as boolean}
										helperText={
											(touched.velopassId && errors.velopassId) as string
										}
										helperTextId={
											errors.velopassId && 'velopass-id-helper-text'
										}
										propsToDelegate={{
											onChange: handleChange,
											value: values.velopassId,
											name: 'velopassId',
											disabled:
												bicycle.statusIsSyncing ||
												!isEmptyString(bicycle.velopassId),
										}}
									/>
								</Box>
								<Box className={classes.uploadContainer}>
									<Box>
										<Typography
											id={
												'bicycle-detail-cta-deliver-form-delivery-document-label'
											}
											variant={'body1'}
										>
											{t('BicycleDetailCtaSignedDeliveryDocument')}:
										</Typography>
										<FileUploadAlt
											id={
												'bicycle-detail-cta-deliver-form-delivery-document-upload'
											}
											className={classes.uploadBox}
											uploadedFile={uploadedDocumentFile}
											handleChangeFile={handleChangeDocumentFile}
											handleFileDelete={handleFileDocumentDelete}
											helperText={t('BicycleDetailCtaAddDocument')}
											helperTextId={
												'bicycle-detail-cta-deliver-form-delivery-document-upload-helper-text'
											}
											disabled={bicycle.statusIsSyncing}
											allowedFileTypes={FileType.PDF}
										/>
									</Box>
									<Box>
										<Typography
											id={
												'bicycle-detail-cta-deliver-form-invoice-document-label'
											}
											variant={'body1'}
										>
											{t('BicycleDetailCtaInvoiceDocument')}:
										</Typography>
										<FileUploadAlt
											id={
												'bicycle-detail-cta-deliver-form-invoice-document-upload'
											}
											className={classes.uploadBox}
											uploadedFile={uploadedInvoiceFile}
											handleChangeFile={handleChangeInvoiceFile}
											handleFileDelete={handleFileInvoiceDelete}
											helperText={t('BicycleDetailCtaAddInvoice')}
											helperTextId={
												'bicycle-detail-cta-deliver-form-invoice-document-upload-helper-text'
											}
											disabled={bicycle.statusIsSyncing}
											allowedFileTypes={FileType.PDF}
										/>
									</Box>
								</Box>
								<Box className={classes.buttonsBox}>
									<CustomButton
										id={'bicycle-detail-cta-deliver-form-submit-button'}
										className={classes.updateStatusButton}
										text={
											bicycle.statusIsSyncing
												? t('DealerOrderFlowSyncInProgress')
												: t('BicycleDetailCtaFinishButton')
										}
										propsToDelegate={{
											type: 'submit',
											disabled:
												loading ||
												!uploadedDocumentFile ||
												!uploadedInvoiceFile ||
												!pickupDate ||
												bicycle.statusIsSyncing,
										}}
									/>
								</Box>
							</Box>
						</form>
					)}
				</Formik>
			)}
		</>
	)
}

DeliverForm.propTypes = {
	bicycle: PropTypes.any.isRequired,
}

export default DeliverForm
