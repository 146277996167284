import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

const useStyles = makeStyles((theme) => ({
	titleRow: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	title: {
		color: '#08080d',
		fontSize: 36,
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
	},
	subTitle: {
		color: '#7e7e80',
		fontSize: 16,
		fontWeight: 500,
		marginTop: theme.spacing(12 / 8),
		width: `calc(100vw / 12 * 7 - ${LARGE_GUTTER_SIZE * 4}px)`,
	},
	dateWarningDisclaimer: {
		color: '#000',
	},
	dateWarningDate: {
		textDecoration: 'underline',
	},
	inputBox: {
		width: `calc(100vw / 12 * 4 - ${LARGE_GUTTER_SIZE * 4}px)`,
	},
	dateInputContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(24 / 8),
	},
	readyForPickupCheckboxContainer: {
		display: 'flex',
		marginTop: theme.spacing(-12 / 8),
	},
	buttonsBox: {
		marginTop: theme.spacing(40 / 8),
		marginLeft: `calc(100vw / 12 * 5 - ${LARGE_GUTTER_SIZE * 4}px)`,
	},
	updateStatusButton: {
		width: `calc(100vw / 12 * 3 - ${LARGE_GUTTER_SIZE * 2}px)`,
		padding: theme.spacing(12 / 8),
	},
	extraFormContainer: {
		borderTop: 'solid 1px #d7dfe9',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		marginTop: theme.spacing(32 / 8),
		padding: theme.spacing(32 / 8, 0),
	},
	licenseInput: {
		marginTop: theme.spacing(16 / 8),
	},
	errorMessageBox: {
		marginTop: theme.spacing(24 / 8),
	},
}))

export default useStyles
