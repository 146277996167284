import {Box, Typography} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	CustomButtonComponent as CustomButton,
	ErrorMessageBoxComponent as ErrorMessageBox,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {DownloadIconSvg} from '@svg/index'
import {LanguageContext} from '@utils/context'
import {useDeliveryDocumentDownload} from '@utils/hooks'
import {Bicycle} from '@utils/types'

import useStyles from './style.hook'

type StatusIsSyncingPlaceholderProps = {
	bicycle: Bicycle
}

const StatusIsSyncingPlaceholder: React.FC<StatusIsSyncingPlaceholderProps> = ({
	bicycle,
}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)
	const downloadDocument = useDeliveryDocumentDownload(bicycle)
	const dispatch = useDispatch()

	const {error} = useSelector((state: RootState) => state.orderFlow)

	const goBack = () => {
		dispatch(navigate(`/${activeLanguage}/app/bicycles`))
	}

	return (
		<Box>
			<Box className={classes.titleRow}>
				<Typography
					id={'bicycle-detail-status-is-syncing-placeholder-title'}
					className={classes.title}
					variant={'body1'}
				>
					{t('BicycleDetailStatusIsSyncingPlaceholderTitle')}
				</Typography>
				<CustomButton
					type={'text'}
					text={t('BicycleDetailCtaDeliverBicycleSubTitlePart3')}
					startIcon={<DownloadIconSvg />}
					propsToDelegate={{
						onClick: downloadDocument,
					}}
				/>
			</Box>
			<Typography
				id={'bicycle-detail-status-is-syncing-placeholder-subtitle-1'}
				className={classes.subTitle}
				variant={'body1'}
			>
				{t('BicycleDetailStatusIsSyncingPlaceholderSubtitle1')}
			</Typography>
			<Typography
				id={'bicycle-detail-status-is-syncing-placeholder-subtitle-2'}
				className={classes.subTitle}
				variant={'body1'}
			>
				{t('BicycleDetailStatusIsSyncingPlaceholderSubtitle2')}
			</Typography>
			{error && (
				<ErrorMessageBox
					id="status-is-syncing-placeholder-error-message-box"
					className={classes.errorMessageBox}
					errorMessage={t(error)}
				/>
			)}
			<Box className={classes.buttonsBox}>
				<CustomButton
					className={classes.backButton}
					id={
						'bicycle-detail-status-is-syncing-placeholder-back-to-overview-button'
					}
					text={t(
						'BicycleDetailStatusIsSyncingPlaceholderBackToOverviewButton'
					)}
					propsToDelegate={{
						onClick: goBack,
					}}
				/>
			</Box>
		</Box>
	)
}

StatusIsSyncingPlaceholder.propTypes = {
	bicycle: PropTypes.any.isRequired,
}

export default StatusIsSyncingPlaceholder
