import {TFunction} from 'i18next'
import * as Yup from 'yup'

import {FormValidationErrorMessages} from '@utils/types/form-validation-error-messages'

export const deliveryDateGivenFormSchema = (
	t: TFunction
): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		deliveryDate: Yup.date().typeError(
			t(FormValidationErrorMessages.DATE_TYPE_ERROR)
		),
	})

export const deliveryDateGivenFormSchemaWithPickupDate = (
	t: TFunction
): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		actualDeliveryDate: Yup.date().when(
			'readyForPickup',
			(readyForPickup: boolean, schema: any) => {
				if (readyForPickup) {
					return schema.typeError(
						t(FormValidationErrorMessages.DATE_TYPE_ERROR)
					)
				}

				return schema.nullable().default(null)
			}
		),
		readyForPickup: Yup.boolean().notRequired(),
	})

export const deliveryDateGivenFormSchemaSpeedbike = (
	t: TFunction
): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		deliveryDate: Yup.date().typeError(
			t(FormValidationErrorMessages.DATE_TYPE_ERROR)
		),
		actualDeliveryDate: Yup.date().when(
			'readyForPickup',
			(readyForPickup: boolean, schema: any) => {
				if (readyForPickup) {
					return schema.typeError(
						t(FormValidationErrorMessages.DATE_TYPE_ERROR)
					)
				}

				return schema.nullable().default(null)
			}
		),
		readyForPickup: Yup.boolean().notRequired(),
		frameNumber: Yup.string().required(t(FormValidationErrorMessages.REQUIRED)),
		keyNumber: Yup.string().required(t(FormValidationErrorMessages.REQUIRED)),
		numberPlate: Yup.string().required(t(FormValidationErrorMessages.REQUIRED)),
	})
