import {TFunction} from 'i18next'
import * as Yup from 'yup'

import {FormValidationErrorMessages} from '@utils/types/form-validation-error-messages'

export const orderedFormSchema = (t: TFunction): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		deliveryDate: Yup.date()
			.nullable(true)
			.typeError(t(FormValidationErrorMessages.DATE_TYPE_ERROR))
			.default(null),
	})
