import {Box, Typography} from '@material-ui/core'
import {Formik} from 'formik'
import PropTypes from 'prop-types'
import React, {ReactNode} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	CustomAlertBoxComponent as CustomAlertBox,
	CustomButtonComponent as CustomButton,
	CustomDatePickerComponent as CustomDatePicker,
	ErrorMessageBoxComponent as ErrorMessageBox,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {DownloadIconSvg} from '@svg/index'
import {orderedFormSchema} from '@templates/BicycleDetail/Cta/OrderedForm/ordered-form-validation'
import {
	orderConfirm,
	orderDeliveryDateGiven,
} from '@templates/BicycleDetail/Cta/bicycle-cta-slice'
import {formatIsoToPrettyDate} from '@utils/date'
import {useDeliveryDocumentDownload} from '@utils/hooks'
import {Bicycle, CommonProps} from '@utils/types'

import useStyles from './style.hook'

interface OrderedFormProps extends CommonProps {
	bicycle: Bicycle
}

interface FormValues {
	deliveryDate: Date
}

const OrderedForm: React.FC<OrderedFormProps> = ({bicycle}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const dispatch = useDispatch()
	const downloadDocument = useDeliveryDocumentDownload(bicycle)

	const {loading, error} = useSelector((state: RootState) => state.orderFlow)

	const initialFormValues: FormValues = {
		deliveryDate: null as any,
	}

	const confirmOrder = (values: FormValues): void => {
		if (values.deliveryDate) {
			dispatch(orderDeliveryDateGiven(bicycle.bicycleId, values.deliveryDate))
		} else {
			dispatch(orderConfirm(bicycle.bicycleId))
		}
	}

	const dateValuesValid = (values: FormValues) => {
		if (bicycle.doNotDeliverBeforeDate) {
			const doNotDeliverBeforeDate = new Date(bicycle.doNotDeliverBeforeDate)
			doNotDeliverBeforeDate.setHours(0)

			if (values.deliveryDate) {
				return (
					values.deliveryDate &&
					values.deliveryDate.getTime() >= doNotDeliverBeforeDate.getTime()
				)
			}
		}

		return true
	}

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={orderedFormSchema(t)}
			onSubmit={confirmOrder}
		>
			{({values, errors, touched, handleSubmit, setFieldValue}): ReactNode => (
				<form onSubmit={handleSubmit}>
					<Box className={classes.titleRow}>
						<Typography
							id={'bicycle-detail-cta-ordered-form-title'}
							className={classes.title}
							variant={'body1'}
						>
							{t('BicycleDetailCtaQuotationApproved')}
						</Typography>
						<CustomButton
							type={'text'}
							text={t('BicycleDetailCtaDeliverBicycleSubTitlePart3')}
							startIcon={<DownloadIconSvg />}
							propsToDelegate={{
								onClick: downloadDocument,
							}}
						/>
					</Box>
					<Typography
						id={'bicycle-detail-cta-ordered-form-subtitle'}
						className={classes.subTitle}
						variant={'body1'}
					>
						{t('BicycleDetailCtaQuotationApprovedSubTitle')}
					</Typography>
					{error && (
						<ErrorMessageBox
							id="ordered-form-error-message-box"
							className={classes.errorMessageBox}
							errorMessage={t(error)}
						/>
					)}
					{bicycle.doNotDeliverBeforeDate && (
						<CustomAlertBox>
							<Trans i18nKey="BicycleDetailDoNotDeliverBeforeDateDisclaimer">
								<span className={classes.dateWarningDisclaimer}>
									Disclaimer:
								</span>
								<span className={classes.dateWarningDate}>
									{{
										date: formatIsoToPrettyDate(bicycle.doNotDeliverBeforeDate),
									}}
								</span>
							</Trans>
						</CustomAlertBox>
					)}
					<CustomDatePicker
						id={'bicycle-detail-cta-ordered-form-delivery-date'}
						className={classes.deliveryInput}
						label={t('BicycleDetailEstimatedDeliveryDateLabel')}
						error={errors.deliveryDate && touched.deliveryDate !== false}
						helperText={touched.deliveryDate && (errors.deliveryDate as string)}
						helperTextId={errors.deliveryDate && 'delivery-date-helper-text'}
						propsToDelegate={{
							name: 'deliveryDate',
							value: values.deliveryDate,
							disabled: bicycle.statusIsSyncing,
							emptyLabel: t('NoDeliveryDateGiven'),
							onChange: (value): void =>
								setFieldValue('deliveryDate', value, true),
							minDate: bicycle.doNotDeliverBeforeDate
								? new Date(bicycle.doNotDeliverBeforeDate)
								: null,
						}}
					/>
					<Box className={classes.buttonsBox}>
						<CustomButton
							id={'bicycle-detail-cta-ordered-form-confirm-button'}
							className={classes.confirmButton}
							text={
								bicycle.statusIsSyncing
									? t('DealerOrderFlowSyncInProgress')
									: t('BicycleDetailConfirmOrderButton')
							}
							propsToDelegate={{
								type: 'submit',
								disabled:
									loading ||
									bicycle.statusIsSyncing ||
									!dateValuesValid(values),
							}}
						/>
					</Box>
				</form>
			)}
		</Formik>
	)
}

OrderedForm.propTypes = {
	bicycle: PropTypes.any.isRequired,
}

export default OrderedForm
