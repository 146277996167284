import {TFunction} from 'i18next'
import * as Yup from 'yup'

import {FormValidationErrorMessages} from '@utils/types/form-validation-error-messages'

export const DeliverFormSchema = (t: TFunction): Yup.ObjectSchema<any> =>
	Yup.object().shape({
		velopassId: Yup.string()
			.required(t(FormValidationErrorMessages.REQUIRED))
			.min(
				10,
				(obj) =>
					`${t(FormValidationErrorMessages.STRING_MIN)} (Min. ${obj.min})`
			)
			.max(
				10,
				(obj) =>
					`${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
			),
	})
