import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	cardEffect: {
		backgroundColor: '#ffffff',
		borderRadius: theme.spacing(8 / 8),
		border: `solid 1px rgba(0, 0, 0, 0.16)`,
	},
	ctaContainer: {
		padding: theme.spacing(30 / 8),
		marginTop: theme.spacing(32 / 8),
	},
}))

export default useStyles
