import dayjs from 'dayjs'

export const formatIsoToPrettyDate = (date: string): string =>
	(date && dayjs(date).format('DD/MM/YYYY')) || 'N/A'

export const endOfToday = (): Date => {
	const endOfDay = new Date()
	endOfDay.setHours(23, 59, 59)
	return endOfDay
}
