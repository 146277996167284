import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside UserActionLog functional component
 */

const useStyles = makeStyles((theme) => ({
	cardEffect: {
		position: 'sticky',
		top: theme.spacing(185 / 8),
		padding: '16px 8px 8px !important',
		marginRight: theme.spacing(32 / 8),
		maxWidth: '100%',
		backgroundColor: '#fff',
		boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
		borderRadius: 8,
	},
	title: {
		paddingLeft: theme.spacing(8 / 8),
		paddingBottom: theme.spacing(12 / 8),
		fontSize: 16,
		fontWeight: 700,
	},
	activitiesContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		padding: theme.spacing(8 / 8),
		backgroundColor: '#EDF2F8',
		borderRadius: 8,
	},
	activityCard: {
		padding: theme.spacing(12 / 8),
		backgroundColor: '#fff',
		border: '1px solid #91A1BB',
		borderRadius: 8,
	},
	activityType: {
		display: 'flex',
		gap: 5,
		color: '#524FA2',

		'& p': {
			fontSize: 16,
			fontWeight: 500,
		},
	},
	description: {
		fontSize: 16,
		fontWeight: 700,
	},
	dateTime: {
		color: '#7E7E80',
		fontSize: 16,
		fontWeight: 500,
	},
}))

export default useStyles
