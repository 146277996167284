import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE, SMALL_GUTTER_SIZE} from '@styles/mui-theme'

/**
 * Component-scoped styles which should be used as a hook
 * inside BicycleDetail functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#edf2f8',
	},
	mainContentContainer: {
		marginTop: theme.spacing(60 / 8),
	},
	backToOverviewContainer: {
		display: 'flex',
		cursor: 'pointer',
		alignItems: 'center',
	},
	backToOverviewText: {
		fontFamily: 'Montserrat,sans-serif',
		fontSize: 16,
		fontWeight: 'bold',
		color: '#08080d',
		marginLeft: theme.spacing(13 / 8),
	},
	cardEffect: {
		backgroundColor: '#ffffff',
		borderRadius: theme.spacing(8 / 8),
		border: `solid 1px rgba(0, 0, 0, 0.16)`,
	},
	bicycleDetailCard: {
		marginTop: theme.spacing(33 / 8),
		paddingBottom: theme.spacing(120 / 8),
	},
	cardHeader: {
		height: theme.spacing(143 / 8),
		backgroundImage: 'linear-gradient(to bottom, #0d4239, #56847f)',
		borderTopRightRadius: theme.spacing(8 / 8),
		borderTopLeftRadius: theme.spacing(8 / 8),
		marginBottom: theme.spacing(32 / 8),
	},
	headerImageBackgroundBox: {
		position: 'absolute',
		background: 'url(/images/bicycle-detail-background.svg)',
		backgroundPosition: '1% 50%',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		height: theme.spacing(143 / 8),
		[theme.breakpoints.up('xl')]: {
			backgroundRepeat: 'repeat',
		},
	},
	headerTitleBox: {
		paddingTop: theme.spacing(48 / 8),
		paddingLeft: `calc(100vw / 12)`,
	},
	headerSoNumberLabel: {
		color: '#ffffff',
		fontWeight: 500,
	},
	headerSoNumberValue: {
		color: '#ffffff',
		fontWeight: 'bold',
		fontSize: 36,
		fontFamily: 'Montserrat,sans-serif',
	},
	dateWarning: {
		margin: '0 16px',
	},
	dateWarningTitle: {
		color: '#000',
	},
	dateWarningDate: {
		textDecoration: 'underline',
	},
	accordionsContainer: {
		marginLeft: `calc(100vw / 12 - ${LARGE_GUTTER_SIZE}px)`,
		marginRight: `calc(100vw / 12 - ${LARGE_GUTTER_SIZE}px)`,
	},
	accordion: {
		boxShadow: 'none',
	},
	accordionDetail: {
		display: 'flex',
		flexDirection: 'column',
	},
	accordionDetailColumns: {
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	accordionDetailSection: {
		marginBottom: theme.spacing(16 / 8),
	},
	accordionDetailSectionColumns: {
		width: '50%',
	},
	accordionDetailSectionEntireColumn: {
		width: '100%',
	},
	upload: {
		marginBottom: theme.spacing(26 / 8),
	},
	insuranceButton: {
		padding: theme.spacing(14 / 8, 0),
	},
	accordionTile: {
		fontSize: 20,
		fontWeight: 'bold',
	},
	accordionDetailTitle: {
		color: '#7e7e80',
		fontSize: 16,
		fontWeight: 500,
		width: `calc(100% - ${SMALL_GUTTER_SIZE}px)`,
	},
	lastAccordionSection: {
		marginBottom: theme.spacing(0),
	},
	accordionDetailValue: {
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
		wordBreak: 'break-word',
	},
	purpleText: {
		color: '#524fa2',
	},
	redText: {
		color: '#f15a5b',
	},
	greenText: {
		color: '#82ca9c',
	},
	expandIcon: {
		color: '#08080d',
		transform: 'rotate(90deg)',
	},
	expanded: {
		'&$expanded': {
			color: '#08080d',
			transform: 'transformX(0) rotate(180deg)',
		},
	},
	accordionSpacer: {
		backgroundColor: '#d7dfe9',
		height: theme.spacing(1 / 8),
	},
	bottomPadding: {
		height: theme.spacing(120 / 8),
	},
	accessoryTopContainer: {
		display: 'flex',
	},
	accessoryAmountContainer: {
		width: 'calc(10%)',
	},
	accessoryTextContainer: {
		width: `calc(50% - ${SMALL_GUTTER_SIZE}px)`,
	},
	accessoryTitle: {
		color: '#08080d',
		fontSize: 16,
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
	},
	accessoryDescription: {
		color: '#7e7e80',
		fontSize: 16,
		fontWeight: 500,
	},
	accessoryPriceBox: {
		display: 'flex',
		width: 'calc(40%)',
		paddingLeft: `calc(11%)`,
	},
	accessoryAmountBox: {
		borderRadius: theme.spacing(8 / 8),
		border: 'solid 1px #91a1bb',
		marginBottom: theme.spacing(12 / 8),
		width: theme.spacing(32 / 8),
		height: theme.spacing(32 / 8),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#524fa2',
		fontSize: 16,
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
	},
	serviceStriked: {
		fontFamily: 'Montserrat,sans-serif',
		fontWeight: 600,
		color: '#7e7e80',
		textDecoration: 'line-through',
	},
	attachmentsRow: {
		display: 'flex',
		width: '100%',
		marginTop: theme.spacing(8 / 8),
	},
	fileName: {
		fontFamily: 'Montserrat,sans-serif',
		fontWeight: 'bold',
		color: '#08080d',
		fontSize: 16,
		marginLeft: theme.spacing(20 / 8),
		width: '70%',
	},
	downloadButton: {
		border: 'solid 1px #524fa2',
		color: '#524fa2',
		fontWeight: 600,
		fontSize: 14,
		padding: theme.spacing(0, 24 / 8),
		lineHeight: 'normal',
		height: theme.spacing(32 / 8),
	},
	addMaintenanceModal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	addMaintenanceModalCard: {
		width: theme.spacing(750 / 8),
		padding: theme.spacing(34 / 8, 30 / 8, 30 / 8, 30 / 8),
	},
	addMaintenanceModalTitle: {
		fontSize: 24,
		fontWeight: 'bold',
	},
	addMaintenanceModalText: {
		color: '#7E7E80',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(8),
	},
	addMaintenanceModalButtons: {
		display: 'flex',
	},
	addMaintenanceModalCancelButton: {
		width: '28%',
		marginLeft: 'auto',
		backgroundColor: '#F1F2F6',
		padding: theme.spacing(8 / 8, 0),
		'&:hover': {
			backgroundColor: 'rgba(241, 242, 246, 0.8)',
			borderColor: 'rgba(255, 255, 255, 1)',
		},
		'&:active': {
			backgroundColor: 'rgba(241, 242, 246, 0.7)',
			borderColor: 'rgba(255, 255, 255, 1)',
		},
	},
	addMaintenanceModalUploadButton: {
		width: '28%',
		marginLeft: theme.spacing(30 / 8),
	},
	maintenanceHistoryContainer: {
		borderRadius: theme.spacing(1),
		border: 'solid 1px #91a1bb',
		'&:nth-child(2)': {
			marginTop: theme.spacing(1 / 2),
		},
		'&:not(:nth-child(2))': {
			marginTop: theme.spacing(1),
		},
	},
	maintenanceHistoryDealer: {
		paddingLeft: theme.spacing(12 / 8),
		paddingRight: theme.spacing(10 / 8),
		fontSize: 16,
		fontWeight: 'bold',
		color: '#08080d',
	},
	maintenanceHistoryDate: {
		fontWeight: 500,
		color: '#7e7e80',
	},
	maintenanceHistoryPrice: {
		marginLeft: 'auto',
		paddingRight: theme.spacing(12 / 8),
		fontSize: 16,
		fontWeight: 'bold',
	},
	maintenanceHistoryDescriptionContainer: {
		paddingBottom: theme.spacing(12 / 8),
		paddingLeft: theme.spacing(12 / 8),
		paddingRight: theme.spacing(12 / 8),
		width: '50%',
	},
	maintenanceHistoryDescription: {
		fontWeight: 500,
		color: '#7e7e80',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		'&:nth-child(1)': {
			paddingTop: theme.spacing(12 / 8),
			paddingBottom: theme.spacing(1 / 2),
		},
	},
	successSubTitle: {
		color: '#7e7e80',
		textAlign: 'center',
	},
	errorBox: {
		marginTop: theme.spacing(24 / 8),
	},
	userActionLogGrid: {
		padding: theme.spacing(0, 15 / 8, 0, 30 / 8),
	},
}))

export default useStyles
