import {Button, Typography} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	TemplateContainerComponent as TemplateContainer,
	CyclisHeaderComponent as CyclisHeader,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {AnimatedTextSvg, CyclisLogoSvg} from '@svg/index'
import {LanguageContext} from '@utils/context'

import './400.css'

interface BadRequestPageProps {
	errorMessageFromMiddleware: string
}

const BadRequestPage: React.FC<BadRequestPageProps> = ({
	errorMessageFromMiddleware,
}) => {
	const dispatch = useDispatch()

	const {isAuthenticated} = useSelector((state: RootState) => state.auth)

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)

	const {t} = useTranslation(undefined, {useSuspense: false})

	const {activeLanguage} = useContext(LanguageContext)

	const navigateBack = (): void => {
		dispatch(navigate(`/${activeLanguage}/app`))
	}

	return (
		<TemplateContainer id="bad-request-page">
			{isAuthenticated ? (
				<CyclisHeader customerName={`${firstName} ${lastName}`} />
			) : (
				<CyclisLogoSvg className="logo" color />
			)}

			<AnimatedTextSvg className="animatedTextSvg" text="400" fontSize={200} />
			<Typography variant="h3" className="badRequestTitle">
				{t('BadRequestTitle')}
			</Typography>
			<Typography variant="body1" className="badRequestSubtitle">
				{t(errorMessageFromMiddleware)}
			</Typography>
			<Button
				id="go-back"
				className="badRequestGoBackButton"
				variant="contained"
				color="primary"
				onClick={navigateBack}
			>
				{t('BadRequestGoBackButton')}
			</Button>
		</TemplateContainer>
	)
}

BadRequestPage.propTypes = {
	errorMessageFromMiddleware: PropTypes.string.isRequired,
}

export default BadRequestPage
