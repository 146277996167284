import {
	Box,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	Modal,
	Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	CustomButtonComponent as CustomButton,
	CyclisHeaderComponent as CyclisHeader,
	CustomSuccessComponent as CustomSuccess,
	FileUploadComponent as FileUpload,
	TemplateContainerComponent,
	ErrorMessageBoxComponent as ErrorMessageBox,
} from '@components/index'
import BadRequestPage from '@pages/400'
import NotFoundPage from '@pages/404'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {PdfIconSvg} from '@svg/index'
import {
	getBicycleDeliveryDocumentDownloadUrl,
	getBicycleDetail,
	resetBicycleDeliveryDocument,
	resetBicycleDetailState,
	resetBicycleMaintenanceDocument,
	uploadMaintenanceDocument,
} from '@templates/BicycleDetail/bicycle-detail-slice'
import {NavigationStorageItems} from '@utils/constants/local-storage-constants'
import {NO_GOOGLE_TRANSLATE} from '@utils/constants/util-constants'
import {LanguageContext} from '@utils/context'
import {formatIsoToPrettyDate} from '@utils/date'
import {downloadFileFromUrl} from '@utils/download-helper'
import {EnumDictionary} from '@utils/enum-utils'
import {SortDirection} from '@utils/hooks/TableDataProvider'
import {toPrettyPrice} from '@utils/string-utils'
import {CommonProps, CyclisDocumentType} from '@utils/types'
import {UsageHistoryItem} from '@utils/types/bicycle'
import {FileType} from '@utils/types/file-types'
import {BicycleStatus} from '@utils/types/status'

import Cta from './Cta'
import UserActionLog from './UserActionLog'
import useStyles from './style.hook'

/**
 * BicycleDetail
 */
interface BicycleDetailLocation extends Location {
	state: {
		bicycleStatus?: BicycleStatus[]
		searchTerm?: string
		sort?: {
			direction: SortDirection
			property: string
		}
	}
}
interface BicycleDetailProps extends CommonProps {
	location: BicycleDetailLocation
}

interface UsageHistoryEntryProps extends CommonProps {
	item: UsageHistoryItem
}

const BicycleDetail: React.FC<BicycleDetailProps> = ({location}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const dispatch = useDispatch()

	const {t} = useTranslation(undefined, {useSuspense: false})

	const [uploadedFile, setUploadedFile] = useState<File>(undefined as any)
	const [modalOpen, setModalOpen] = useState<boolean>(false)

	const {activeLanguage} = useContext(LanguageContext)
	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const {
		bicycle,
		documentLoading,
		error,
		loading,
		maintenanceUploadLoading,
		maintenanceUploadSuccess,
		documentDownloadUrl,
		documentSuccess,
	} = useSelector((state: RootState) => state.bicycleDetail)

	const goBack = (): void => {
		let url = `/${activeLanguage}/app/`
		const previousLocation = localStorage.getItem(
			NavigationStorageItems.PREVIOUS_LOCATION
		)

		if (previousLocation) {
			url += previousLocation
		} else {
			url += 'bicycles'
		}

		dispatch(
			navigate(url, {
				state: {
					sort: (location.state && location.state.sort) || undefined,
					searchTerm:
						(location.state && location.state.searchTerm) || undefined,
					status: (location.state && location.state.bicycleStatus) || undefined,
				},
			})
		)
	}

	const startDocumentDownload = (documentId: string): void => {
		if (bicycle.attachments && bicycle.attachments.length) {
			dispatch(
				getBicycleDeliveryDocumentDownloadUrl(bicycle.bicycleId, documentId)
			)
		}
	}

	/**
	 * Fetch bicycle detail data from id and optional search term in query params
	 */
	useEffect(() => {
		const {search} = location
		const params = new URLSearchParams(search)
		const parsedBicycleId = Number(params.get('id'))

		dispatch(getBicycleDetail(parsedBicycleId))
	}, [location])

	/**
	 * Clear bicycle detail redux state on unmount
	 */
	useEffect(() => {
		return (): void => {
			dispatch(resetBicycleDetailState())
			dispatch(resetBicycleDeliveryDocument())
		}
	}, [])

	/**
	 * Start the browser download once there's a document download url available
	 */
	useEffect(() => {
		if (documentSuccess && documentDownloadUrl) {
			downloadFileFromUrl(documentDownloadUrl)
		}
	}, [documentSuccess, documentDownloadUrl])

	const statusMapping: EnumDictionary<BicycleStatus, string> = {
		[BicycleStatus.ALL]: t('BicycleStatusAll'),
		[BicycleStatus.CYCLIS_ORDERED]: t('BicycleStatusCyclisOrdered'),
		[BicycleStatus.DEALER_ORDERED]: t('BicycleStatusDealerOrdered'),
		[BicycleStatus.DELIVERY_DATE_GIVEN]: t('BicycleStatusDeliveryDateGiven'),
		[BicycleStatus.READY_FOR_PICKUP]: t('BicycleStatusReadyForPickup'),
		[BicycleStatus.PICKUP_INFO_AVAILABLE]: t(
			'BicycleStatusPickupInfoAvailable'
		),
		[BicycleStatus.DELIVERY_COMPLETED]: t('BicycleStatusDeliveryCompleted'),
		[BicycleStatus.CUSTOMER_LEASE_CANCELED]: t('BicycleStatusCanceled'),
		[BicycleStatus.END_LEASE]: t('BicycleStatusEndLease'),
	}

	const handleChangeFile = (e: ChangeEvent<HTMLInputElement>): void => {
		const file = e.target.files![0]
		setUploadedFile(file)
		setModalOpen(true)
	}

	const handleFileDelete = (): void => {
		setUploadedFile(undefined as any)
	}

	const toggleModal = (): void => {
		handleFileDelete()
		setModalOpen(false)
	}

	const uploadFile = async (): Promise<void> => {
		dispatch(
			uploadMaintenanceDocument(uploadedFile, bicycle.bicycleId.toString())
		)
	}

	useEffect(() => {
		if (maintenanceUploadSuccess && !error) {
			toggleModal()
		}
	}, [maintenanceUploadSuccess, error])

	const AddMaintenanceModal: React.FC = () => (
		<Modal
			id={'bicycle-detail-add-maintenance-modal'}
			className={classes.addMaintenanceModal}
			open={modalOpen}
			onClose={toggleModal}
		>
			<Box
				id={'bicycle-detail-add-maintenance-modal-card'}
				className={classNames(
					classes.cardEffect,
					classes.addMaintenanceModalCard
				)}
			>
				<Box id={'bicycle-detail-add-maintenance-modal-content'}>
					<Typography
						id={'bicycle-detail-add-maintenance-modal-title'}
						className={classes.addMaintenanceModalTitle}
						variant={'h3'}
					>
						{t('BicycleDetailAddMaintenanceModalTitle')}
					</Typography>
					<Typography
						id={'bicycle-detail-add-maintenance-modal-text'}
						className={classes.addMaintenanceModalText}
					>
						{t('BicycleDetailAddMaintenanceModalText')}
					</Typography>
				</Box>
				<Box
					id={'bicycle-detail-add-maintenance-modal-buttons'}
					className={classes.addMaintenanceModalButtons}
				>
					<CustomButton
						id={'bicycle-detail-add-maintenance-modal-cancel-button'}
						text={t('BicycleDetailAddMaintenanceModalCancelButton')}
						className={classes.addMaintenanceModalCancelButton}
						type={'outlined'}
						propsToDelegate={{
							onClick: toggleModal,
						}}
					/>
					<CustomButton
						id={'bicycle-detail-add-maintenance-modal-upload-button'}
						text={t('BicycleDetailAddMaintenanceModalUploadButton')}
						className={classes.addMaintenanceModalUploadButton}
						propsToDelegate={{
							onClick: uploadFile,
							disabled: maintenanceUploadLoading,
						}}
					/>
				</Box>
				{error && (
					<ErrorMessageBox
						id="bicycle-detail-error-message-box"
						className={classes.errorBox}
						errorMessage={t(error)}
					/>
				)}
			</Box>
		</Modal>
	)

	const MaintenanceAccordion: React.FC = () => (
		<>
			<ExpansionPanel
				id={'bicycle-detail-maintenance-accordion'}
				defaultExpanded
				className={classes.accordion}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandLessIcon />}
					classes={{
						expandIcon: classes.expandIcon,
						expanded: classes.expanded,
					}}
					aria-controls="maintenance-content"
				>
					<Typography
						id={'bicycle-detail-maintenance-title'}
						className={classes.accordionTile}
						variant={'h3'}
					>
						{t('BicycleDetailMaintenanceTitle')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					className={classNames(
						classes.accordionDetail,
						classes.accordionDetailColumns
					)}
				>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							className={classes.accordionDetailTitle}
							id={'bicycle-detail-maintenance-available-label'}
							variant={'body1'}
						>
							{t('BicycleDetailMaintenanceAvailable')}:
						</Typography>
						<Typography
							id={'bicycle-detail-maintenance-available-value'}
							className={classNames(
								classes.accordionDetailValue,
								classes.purpleText
							)}
							variant={'body1'}
						>
							{toPrettyPrice(Number(bicycle.availableMaintenanceBudget))}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							className={classes.accordionDetailTitle}
							id={'bicycle-detail-maintenance-total-label'}
							variant={'body1'}
						>
							{t('BicycleDetailMaintenanceTotal')}:
						</Typography>
						<Typography
							id={'bicycle-detail-maintenance-available-value'}
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{toPrettyPrice(Number(bicycle.maintenanceBudget || 0))}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-maintenance-spend-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailMaintenanceSpend')}:
						</Typography>
						<Typography
							id={'bicycle-detail-maintenance-available-value'}
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{toPrettyPrice(Number(bicycle.maintenanceSpend || 0))}
						</Typography>
					</Box>
					{bicycle.usageHistory && bicycle.usageHistory.length !== 0 && (
						<Box
							className={classNames(
								classes.accordionDetailSection,
								classes.accordionDetailSectionEntireColumn
							)}
						>
							<Typography
								id={'bicycle-detail-maintenance-history-label'}
								className={classes.accordionDetailTitle}
								variant={'body1'}
							>
								{t('BicycleDetailMaintenanceHistory')}:
							</Typography>
							{bicycle.usageHistory.map((item, index) => (
								<UsageHistoryEntry key={index} item={item} />
							))}
						</Box>
					)}
					{bicycle.bicycleStatus === BicycleStatus.DELIVERY_COMPLETED && (
						<Box
							className={classNames(
								classes.accordionDetailSection,
								classes.accordionDetailSectionEntireColumn
							)}
						>
							<FileUpload
								className={classes.upload}
								uploadedFile={null as any}
								helperText={t('BicycleDetailMaintenanceUploadHelperText')}
								helperTextId={'upload-helper-text'}
								allowedFileTypes={[
									FileType.PDF,
									FileType.JPG,
									FileType.JPEG,
									FileType.PNG,
								].join(',')}
								handleChangeFile={handleChangeFile}
								handleFileDelete={handleFileDelete}
							/>
						</Box>
					)}
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Box className={classes.accordionSpacer} />
		</>
	)

	const InsurancesAccordion: React.FC = () => (
		<>
			<ExpansionPanel
				id={'bicycle-detail-insurances-accordion'}
				defaultExpanded
				className={classes.accordion}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandLessIcon />}
					classes={{
						expandIcon: classes.expandIcon,
						expanded: classes.expanded,
					}}
					aria-controls="insurances-content"
				>
					<Typography
						id={'bicycle-detail-insurances-title'}
						className={classes.accordionTile}
						variant={'h3'}
					>
						{t('BicycleDetailInsurancesTitle')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					className={classNames(
						classes.accordionDetail,
						classes.accordionDetailColumns
					)}
				>
					<CustomButton
						id={'bicycle-detail-insurance-button'}
						className={classNames(
							classes.accordionDetailSectionEntireColumn,
							classes.insuranceButton
						)}
						text={t('BicycleDetailInsurancesButtonText')}
						propsToDelegate={{
							onClick: (): void => {
								dispatch(
									navigate(
										`/${activeLanguage}/app/bicycles/damages/new?id=${bicycle.bicycleId}`
									)
								)
							},
						}}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Box className={classes.accordionSpacer} />
		</>
	)

	const LeaseDetailsAccordion: React.FC = () => (
		<>
			<ExpansionPanel
				id={'bicycle-detail-lease-details-accordion'}
				defaultExpanded
				className={classes.accordion}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandLessIcon />}
					classes={{
						expandIcon: classes.expandIcon,
						expanded: classes.expanded,
					}}
					aria-controls="lease-content"
				>
					<Typography
						id={'bicycle-detail-lease-details-title'}
						className={classes.accordionTile}
						variant={'h3'}
					>
						{t('BicycleDetailLeaseDetailsTitle')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					className={classNames(
						classes.accordionDetail,
						classes.accordionDetailColumns
					)}
				>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-so-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailLeaseDetailsSoNum')}:
						</Typography>
						<Typography
							id={'bicycle-detail-so-value'}
							className={classes.accordionDetailValue}
							variant={'body1'}
						>
							{bicycle.salesOrderNumber}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							className={classes.accordionDetailTitle}
							id={'bicycle-detail-status-label'}
							variant={'body1'}
						>
							{t('BicycleDetailLeaseDetailsStatus')}:
						</Typography>
						<Typography
							id={'bicycle-detail-status-value'}
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{bicycle.bicycleStatus
								? statusMapping[bicycle.bicycleStatus]
								: 'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-startcontract-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailLeaseDetailsStartOfContract')}:
						</Typography>
						<Typography
							id={'bicycle-detail-startcontract-value'}
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{bicycle.startOfContract
								? formatIsoToPrettyDate(bicycle.startOfContract)
								: 'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-endcontract-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailLeaseDetailsEndOfContract')}:
						</Typography>
						<Typography
							id={'bicycle-detail-endcontract-value'}
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{bicycle.endOfContract
								? formatIsoToPrettyDate(bicycle.endOfContract)
								: 'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-deliverydate-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailLeaseDetailsExpectedDeliveryDate')}:
						</Typography>
						<Typography
							id={'bicycle-detail-deliverydate-value'}
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{(bicycle.expectedDeliveryDate &&
								formatIsoToPrettyDate(bicycle.expectedDeliveryDate)) ||
								'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-pickupdate-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailLeaseDetailsPickupDate')}:
						</Typography>
						<Typography
							id={'bicycle-detail-pickupdate-value'}
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{(bicycle.pickupDate &&
								formatIsoToPrettyDate(bicycle.pickupDate)) ||
								'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-lease-cost-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailLeaseDetailsLeaseCost')}:
						</Typography>
						<Typography
							id={'bicycle-detail-lease-cost-value'}
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{toPrettyPrice(Number(bicycle.leaseCost || 0))}
						</Typography>
					</Box>
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Box className={classes.accordionSpacer} />
		</>
	)

	const InvolvedPartiesAccordion: React.FC = () => (
		<>
			<ExpansionPanel
				id={'bicycle-detail-invovled-parties-accordion'}
				defaultExpanded
				className={classes.accordion}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandLessIcon />}
					classes={{
						expandIcon: classes.expandIcon,
						expanded: classes.expanded,
					}}
					aria-controls="involved-parties-content"
				>
					<Typography
						id={'bicycle-detail-involved-parties-title'}
						className={classes.accordionTile}
						variant={'h3'}
					>
						{t('BicycleDetailInvolvedPartiesTitle')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					className={classNames(
						classes.accordionDetail,
						classes.accordionDetailColumns
					)}
				>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionEntireColumn
						)}
					>
						<Typography
							id={'bicycle-detail-customer-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailInvolvedPartiesCustomer')}:
						</Typography>
						<Typography
							id={'bicycle-detail-customer-value'}
							className={classNames(
								classes.accordionDetailValue,
								bicycle.customer && NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.customer || 'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-cyclist-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailInvolvedPartiesCyclist')}:
						</Typography>
						<Typography
							id={'bicycle-detail-cyclist-value'}
							className={classNames(
								classes.accordionDetailValue,
								NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.cyclistFirstName
								? `${bicycle.cyclistFirstName} ${bicycle.cyclistLastName}`
								: `/`}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-cyclist-email-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailInvolvedPartiesCyclistEmail')}:
						</Typography>
						<Typography
							id={'bicycle-detail-cyclist-email-value'}
							className={classNames(
								classes.accordionDetailValue,
								NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.cyclistEmail || `/`}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-dealer-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailInvolvedPartiesDealer')}:
						</Typography>
						<Typography
							id={'bicycle-detail-dealer-value'}
							className={classNames(
								classes.accordionDetailValue,
								bicycle.dealer && NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.dealer || 'N/A'}
						</Typography>
					</Box>
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Box className={classes.accordionSpacer} />
		</>
	)

	const BicycleInformationAccordion: React.FC = () => (
		<>
			<ExpansionPanel
				id={'bicycle-detail-bicycle-information-accordion'}
				defaultExpanded
				className={classes.accordion}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandLessIcon />}
					classes={{
						expandIcon: classes.expandIcon,
						expanded: classes.expanded,
					}}
					aria-controls="bicycle-information-content"
				>
					<Typography
						id={'bicycle-detail-bicycle-information-title'}
						className={classes.accordionTile}
						variant={'h3'}
					>
						{t('BicycleDetailBicycleInformationTitle')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					className={classNames(
						classes.accordionDetail,
						classes.accordionDetailColumns
					)}
				>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-brand-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailBicycleInformationBrand')}:
						</Typography>
						<Typography
							id={'bicycle-detail-brand-value'}
							className={classNames(
								classes.accordionDetailValue,
								NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.brand}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							className={classes.accordionDetailTitle}
							id={'bicycle-detail-model-label'}
							variant={'body1'}
						>
							{t('BicycleDetailBicycleInformationModel')}:
						</Typography>
						<Typography
							id={'bicycle-detail-model-value'}
							className={classNames(
								classes.accordionDetailValue,
								NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.model}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							className={classes.accordionDetailTitle}
							id={'bicycle-detail-frametype-label'}
							variant={'body1'}
						>
							{t('BicycleDetailBicycleInformationFrameType')}:
						</Typography>
						<Typography
							id={'bicycle-detail-frametype-value'}
							className={classNames(
								classes.accordionDetailValue,
								NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.frameType}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-framenumber-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailBicycleInformationFrameNumber')}:
						</Typography>
						<Typography
							id={'bicycle-detail-framenumber-value'}
							className={classNames(
								classes.accordionDetailValue,
								bicycle.frameNumber && NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.frameNumber || 'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-type-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailBicycleInformationType')}:
						</Typography>
						<Typography
							id={'bicycle-detail-type-value'}
							className={classNames(
								classes.accordionDetailValue,
								bicycle.type && NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.type || 'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-color-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailBicycleInformationColor')}:
						</Typography>
						<Typography
							id={'bicycle-detail-color-value'}
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{bicycle.color || 'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-numberplate-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailBicycleInformationNumberPlate')}:
						</Typography>
						<Typography
							id={'bicycle-detail-numberplate-value'}
							className={classNames(
								classes.accordionDetailValue,
								bicycle.numberPlate && NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.numberPlate || 'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-keynumber-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailBicycleInformationKeyNumber')}:
						</Typography>
						<Typography
							id={'bicycle-detail-keynumber-value'}
							className={classNames(
								classes.accordionDetailValue,
								bicycle.keyNumber && NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.keyNumber || 'N/A'}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.accordionDetailSectionColumns
						)}
					>
						<Typography
							id={'bicycle-detail-velopass-id-label'}
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailVelopassIdLabel')}
						</Typography>
						<Typography
							id={'bicycle-detail-velopass-id-value'}
							className={classNames(
								classes.accordionDetailValue,
								bicycle.velopassId && NO_GOOGLE_TRANSLATE
							)}
							variant={'body1'}
						>
							{bicycle.velopassId || 'N/A'}
						</Typography>
					</Box>
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Box className={classes.accordionSpacer} />
		</>
	)

	const AccessoriesListAccordion: React.FC = () => (
		<>
			<ExpansionPanel
				id={'bicycle-detail-accessories-accordion'}
				defaultExpanded
				className={classes.accordion}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandLessIcon />}
					classes={{
						expandIcon: classes.expandIcon,
						expanded: classes.expanded,
					}}
					aria-controls="accessories-content"
				>
					<Typography
						id={'bicycle-detail-accessories-title'}
						className={classes.accordionTile}
						variant={'h3'}
					>
						{t('BicycleDetailAccessoriesListTitle')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classNames(classes.accordionDetail)}>
					{bicycle.accessories &&
						bicycle.accessories.map((ac, index) => (
							<Box
								key={index}
								className={classNames(classes.accordionDetailSection)}
							>
								<Box className={classes.accessoryTopContainer}>
									<Box className={classes.accessoryAmountContainer}>
										<Box className={classes.accessoryAmountBox}>
											{ac.quantity}
										</Box>
									</Box>
									<Box className={classes.accessoryTextContainer}>
										<Typography
											className={classes.accessoryDescription}
											variant={'body1'}
										>
											{ac.description}
										</Typography>
									</Box>
								</Box>
							</Box>
						))}
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Box className={classes.accordionSpacer} />
		</>
	)

	const ExtrasAccordion: React.FC = () => (
		<>
			<ExpansionPanel
				id={'bicycle-detail-extras-accordion'}
				defaultExpanded
				className={classes.accordion}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandLessIcon />}
					classes={{
						expandIcon: classes.expandIcon,
						expanded: classes.expanded,
					}}
					aria-controls="maintenance-content"
				>
					<Typography
						id={'bicycle-detail-extras-title'}
						className={classes.accordionTile}
						variant={'h3'}
					>
						{t('BicycleDetailExtrasTitle')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classNames(classes.accordionDetail)}>
					<Box className={classNames(classes.accordionDetailSection)}>
						<Typography
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailExtrasAdditionalServices')}:
						</Typography>
						<Typography
							className={
								bicycle.insurance
									? classes.accordionDetailValue
									: classes.serviceStriked
							}
							variant={'body1'}
						>
							{t('BicycleDetailExtrasAdditionalServicesInsurance')}
						</Typography>
						<Typography
							className={
								bicycle.assistance
									? classes.accordionDetailValue
									: classes.serviceStriked
							}
							variant={'body1'}
						>
							{t('BicycleDetailExtrasAdditionalServicesAssistance')}
						</Typography>
						<Typography
							className={
								bicycle.maintenance
									? classes.accordionDetailValue
									: classes.serviceStriked
							}
							variant={'body1'}
						>
							{t('BicycleDetailExtrasAdditionalServicesMaintenance')}
						</Typography>
					</Box>
					<Box
						className={classNames(
							classes.accordionDetailSection,
							classes.lastAccordionSection
						)}
					>
						<Typography
							className={classes.accordionDetailTitle}
							variant={'body1'}
						>
							{t('BicycleDetailExtrasExtraBudget')}:
						</Typography>
						<Typography
							className={classNames(classes.accordionDetailValue)}
							variant={'body1'}
						>
							{toPrettyPrice(bicycle.extraMaintenance || 0)}
						</Typography>
					</Box>
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Box className={classes.accordionSpacer} />
		</>
	)

	const AttachmentsAccordion: React.FC = () => (
		<>
			<ExpansionPanel
				id={'bicycle-detail-attachments-accordion'}
				defaultExpanded
				className={classes.accordion}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandLessIcon />}
					classes={{
						expandIcon: classes.expandIcon,
						expanded: classes.expanded,
					}}
					aria-controls="attachments-content"
				>
					<Typography
						id={'bicycle-detail-attachments-title'}
						className={classes.accordionTile}
						variant={'h3'}
					>
						{t('BicycleDetailAttachmentsTitle')}
					</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails
					className={classNames(
						classes.accordionDetail,
						classes.accordionDetailColumns
					)}
				>
					{bicycle.attachments &&
						bicycle.attachments
							.filter(
								(a) =>
									a.documentType === CyclisDocumentType.DEALER_QUOTATION ||
									a.documentType === CyclisDocumentType.DELIVERY_DOCUMENT ||
									a.documentType === CyclisDocumentType.PURCHASE_ORDER
							)
							.map((attachment, index) => (
								<Box key={index} className={classes.attachmentsRow}>
									<PdfIconSvg />
									<Typography className={classes.fileName} variant={'body1'}>
										{t(attachment.documentType!)}
									</Typography>
									<CustomButton
										className={classes.downloadButton}
										type={'outlined'}
										text={t('QuotationDetailDownloadButton')}
										propsToDelegate={{
											onClick: (): void =>
												startDocumentDownload(attachment.documentId),
											disabled: documentLoading,
										}}
									/>
								</Box>
							))}
				</ExpansionPanelDetails>
			</ExpansionPanel>
			<Box className={classes.accordionSpacer} />
		</>
	)

	return error === 'StandardErrorNotFound' ? (
		<NotFoundPage />
	) : error && error.includes('StandardError') ? (
		<BadRequestPage errorMessageFromMiddleware={error} />
	) : (
		<TemplateContainerComponent
			id={'bicycle-detail-container'}
			className={classes.root}
		>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			{!loading && maintenanceUploadSuccess ? (
				<CustomSuccess
					id={'bicycle-detail-success-container'}
					headerText={t('BicycleDetailMaintenanceUploadSuccessTitle')}
					subTitle={
						<Typography className={classes.successSubTitle} variant={'body1'}>
							{t('BicycleDetailMaintenanceUploadSuccessSubTitle')}
						</Typography>
					}
					buttonAction={(): void => {
						dispatch(resetBicycleMaintenanceDocument())
					}}
				/>
			) : (
				<Grid container spacing={8}>
					<Grid item xs={bicycle?.lastActions?.length > 0 ? 1 : 2} />
					<Grid item xs={8}>
						<Box className={classes.mainContentContainer}>
							<Box
								id={'bicycle-detail-go-back-container'}
								className={classes.backToOverviewContainer}
								onClick={goBack}
							>
								<ArrowBackIcon id={'bicycle-detail-go-back-icon'} />
								<Typography
									id={'cyclist-profile-go-back-text'}
									className={classes.backToOverviewText}
									variant={'body1'}
								>
									{t('BicycleDetailBackToOverview')}
								</Typography>
							</Box>
							{bicycle && <Cta bicycle={bicycle} />}
							<Box
								className={classNames(
									classes.bicycleDetailCard,
									classes.cardEffect
								)}
							>
								<Box className={classes.cardHeader}>
									<Box className={classes.headerImageBackgroundBox} />
									<Box className={classes.headerTitleBox}>
										{bicycle && (
											<>
												<Typography
													className={classes.headerSoNumberLabel}
													variant={'body1'}
													id={'bicycle-detail-header-so-number-label'}
												>
													{t('BicycleDetailHeaderSoNumber')}
												</Typography>
												<Typography
													className={classNames(
														classes.headerSoNumberValue,
														NO_GOOGLE_TRANSLATE
													)}
													variant={'body1'}
													id={'bicycle-detail-header-so-number-value'}
												>
													{bicycle.salesOrderNumber}
												</Typography>
											</>
										)}
									</Box>
								</Box>
								<Box className={classes.accordionsContainer}>
									{bicycle && (
										<>
											<MaintenanceAccordion />
											{bicycle.bicycleStatus ===
												BicycleStatus.DELIVERY_COMPLETED && (
												<InsurancesAccordion />
											)}
											<LeaseDetailsAccordion />
											<InvolvedPartiesAccordion />
											<BicycleInformationAccordion />
											<AccessoriesListAccordion />
											<ExtrasAccordion />
											<AttachmentsAccordion />
										</>
									)}
								</Box>
							</Box>
						</Box>
					</Grid>
					<Grid
						className={classes.userActionLogGrid}
						item
						xs={bicycle?.lastActions?.length > 0 ? 3 : 2}
					>
						<UserActionLog />
					</Grid>
				</Grid>
			)}
			<Box className={classes.bottomPadding} />
			<AddMaintenanceModal />
		</TemplateContainerComponent>
	)
}

const UsageHistoryEntry: React.FC<UsageHistoryEntryProps> = ({item}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	return (
		<Box className={classes.maintenanceHistoryContainer}>
			<Box className={classes.row}>
				<Typography
					id={'bicycle-detail-maintenance-history-dealer'}
					className={classNames(
						classes.maintenanceHistoryDealer,
						item.description && NO_GOOGLE_TRANSLATE
					)}
					variant={'body1'}
				>
					{item.dealer || 'N/A'}
				</Typography>
				<Typography
					id={'bicycle-detail-maintenance-history-date'}
					className={classes.maintenanceHistoryDate}
					variant={'body1'}
				>
					{formatIsoToPrettyDate(item.date)}
				</Typography>
				<Typography
					id={'bicycle-detail-maintenance-history-price'}
					className={classNames(
						classes.maintenanceHistoryPrice,
						item.amount < 0 ? classes.redText : classes.greenText
					)}
					variant={'body1'}
				>
					{`${item.amount < 0 ? '-' : '+'} ${toPrettyPrice(
						Number(Math.abs(item.amount) || 0)
					)}`}
				</Typography>
			</Box>
			<Box
				className={classNames(
					classes.row,
					classes.maintenanceHistoryDescriptionContainer
				)}
			>
				{item.description}
			</Box>
		</Box>
	)
}

BicycleDetail.propTypes = {
	location: PropTypes.any,
}

UsageHistoryEntry.propTypes = {
	item: PropTypes.any.isRequired,
}

export default BicycleDetail
