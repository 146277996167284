import {Box, Typography} from '@material-ui/core'
import classNames from 'classnames'
import {Formik} from 'formik'
import PropTypes from 'prop-types'
import React, {ReactNode, useMemo} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	CustomAlertBoxComponent as CustomAlertBox,
	CustomButtonComponent as CustomButton,
	CustomCheckboxComponent as CustomCheckbox,
	CustomDatePickerComponent as CustomDatePicker,
	CustomTextInputComponent as CustomTextInput,
	ErrorMessageBoxComponent as ErrorMessageBox,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {DownloadIconSvg} from '@svg/index'
import {
	orderDeliveryDateGiven,
	orderReadyForPickup,
} from '@templates/BicycleDetail/Cta/bicycle-cta-slice'
import {formatIsoToPrettyDate} from '@utils/date'
import {useDeliveryDocumentDownload} from '@utils/hooks'
import {isEmptyString} from '@utils/string-utils'
import {Bicycle, CommonProps} from '@utils/types'
import {BicycleStatus} from '@utils/types/status'

import {
	deliveryDateGivenFormSchema,
	deliveryDateGivenFormSchemaSpeedbike,
	deliveryDateGivenFormSchemaWithPickupDate,
} from './delivery-date-given-form-validation'
import useStyles from './style.hook'

interface DeliveryDateGivenFormProps extends CommonProps {
	bicycle: Bicycle
}

interface FormValues {
	deliveryDate: Date
	actualDeliveryDate: Date
	readyForPickup: boolean
	frameNumber: string
	keyNumber: string
	numberPlate: string
}

const DeliveryDateGivenForm: React.FC<DeliveryDateGivenFormProps> = ({
	bicycle,
}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const dispatch = useDispatch()
	const downloadDocument = useDeliveryDocumentDownload(bicycle)

	const {loading, error} = useSelector((state: RootState) => state.orderFlow)

	const dateValuesValid = (values: FormValues) => {
		if (bicycle.doNotDeliverBeforeDate) {
			const doNotDeliverBeforeDate = new Date(bicycle.doNotDeliverBeforeDate)
			doNotDeliverBeforeDate.setHours(0)

			if (values.readyForPickup) {
				return (
					values.actualDeliveryDate &&
					values.actualDeliveryDate.getTime() >=
						doNotDeliverBeforeDate.getTime()
				)
			}

			return (
				values.deliveryDate &&
				values.deliveryDate.getTime() >= doNotDeliverBeforeDate.getTime()
			)
		}

		return true
	}

	const initialFormValues: FormValues = {
		deliveryDate: (isEmptyString(bicycle.expectedDeliveryDate)
			? null
			: new Date(bicycle.expectedDeliveryDate!)) as any,
		actualDeliveryDate: null as any,
		readyForPickup: false as any,
		frameNumber: bicycle.frameNumber || '',
		keyNumber: bicycle.keyNumber || '',
		numberPlate: bicycle.numberPlate || '',
	}

	const speedBikeTypeId = 638
	const validationSchema =
		bicycle.bicycleStatus === BicycleStatus.DEALER_ORDERED
			? deliveryDateGivenFormSchema(t)
			: bicycle.typeId === speedBikeTypeId
			? deliveryDateGivenFormSchemaSpeedbike(t)
			: deliveryDateGivenFormSchemaWithPickupDate(t)

	const submitForm = (values: FormValues): void => {
		if (values.readyForPickup) {
			const hasPrefilledPickupInfo =
				!isEmptyString(bicycle.frameNumber) ||
				!isEmptyString(bicycle.keyNumber) ||
				!isEmptyString(bicycle.numberPlate)
			dispatch(
				orderReadyForPickup(
					bicycle.bicycleId,
					values.actualDeliveryDate,
					hasPrefilledPickupInfo,
					bicycle.typeId === speedBikeTypeId,
					values.frameNumber,
					values.keyNumber,
					values.numberPlate
				)
			)
		} else {
			dispatch(orderDeliveryDateGiven(bicycle.bicycleId, values.deliveryDate))
		}
	}

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={validationSchema}
			onSubmit={submitForm}
		>
			{({
				values,
				errors,
				touched,
				handleSubmit,
				setFieldValue,
				handleChange,
			}): ReactNode => (
				<form onSubmit={handleSubmit}>
					<Box className={classes.titleRow}>
						<Typography
							id={'bicycle-detail-cta-ready-pickup-form-title'}
							className={classes.title}
							variant={'body1'}
						>
							{t('BicycleDetailCtaKeepYourCustomerUpToDate')}
						</Typography>
						<CustomButton
							type={'text'}
							text={t('BicycleDetailCtaDeliverBicycleSubTitlePart3')}
							startIcon={<DownloadIconSvg />}
							propsToDelegate={{
								onClick: downloadDocument,
							}}
						/>
					</Box>
					<Typography
						id={'bicycle-detail-cta-ready-pickup-form-subtitle'}
						className={classes.subTitle}
						variant={'body1'}
					>
						{t('BicycleDetailKeepYourCustomerUpToDate')}
					</Typography>
					{error && (
						<ErrorMessageBox
							id="delivery-date-given-form-error-message-box"
							className={classes.errorMessageBox}
							errorMessage={t(error)}
						/>
					)}
					{bicycle.doNotDeliverBeforeDate && (
						<CustomAlertBox>
							<Trans i18nKey="BicycleDetailDoNotDeliverBeforeDateDisclaimer">
								<span className={classes.dateWarningDisclaimer}>
									Disclaimer:
								</span>
								<span className={classes.dateWarningDate}>
									{{
										date: formatIsoToPrettyDate(bicycle.doNotDeliverBeforeDate),
									}}
								</span>
							</Trans>
						</CustomAlertBox>
					)}
					<Box className={classes.dateInputContainer}>
						<CustomDatePicker
							id={'bicycle-detail-cta-ready-pickup-form-delivery-date'}
							className={classes.inputBox}
							label={t('BicycleDetailEstimatedDeliveryDateLabel')}
							error={errors.deliveryDate && touched.deliveryDate !== false}
							helperText={
								touched.deliveryDate && (errors.deliveryDate as string)
							}
							helperTextId={errors.deliveryDate && 'delivery-date-helper-text'}
							propsToDelegate={{
								name: 'deliveryDate',
								value: values.readyForPickup
									? new Date(bicycle.expectedDeliveryDate!)
									: values.deliveryDate,
								onChange: (value): void =>
									setFieldValue('deliveryDate', value, true),
								disabled: values.readyForPickup || bicycle.statusIsSyncing,
								emptyLabel: t('NoDeliveryDateGiven'),
								minDate: bicycle.doNotDeliverBeforeDate
									? new Date(bicycle.doNotDeliverBeforeDate)
									: null,
							}}
						/>
						<Box>
							<Box className={classes.readyForPickupCheckboxContainer}>
								<CustomCheckbox
									id={'bicycle-detail-cta-ready-pickup-form-pickup-checkbox'}
									size={16}
									propsToDelegate={{
										checked: values.readyForPickup,
										onChange: handleChange,
										name: 'readyForPickup',
										disabled:
											bicycle.statusIsSyncing || !bicycle.expectedDeliveryDate,
									}}
									label={t('BicycleDetailCtaReadyForPickupLabel')}
								/>
							</Box>
							<CustomDatePicker
								id={'bicycle-detail-cta-ready-pickup-form-pickup-date'}
								className={classes.inputBox}
								error={
									errors.actualDeliveryDate &&
									touched.actualDeliveryDate !== false
								}
								helperText={
									touched.actualDeliveryDate &&
									(errors.actualDeliveryDate as string)
								}
								helperTextId={
									errors.actualDeliveryDate &&
									'actual-delivery-date-helper-text'
								}
								propsToDelegate={{
									name: 'actualDeliveryDate',
									value: values.actualDeliveryDate,
									onChange: (value): void =>
										setFieldValue('actualDeliveryDate', value, true),
									disabled: !values.readyForPickup || bicycle.statusIsSyncing,
									emptyLabel:
										bicycle.bicycleStatus === BicycleStatus.DEALER_ORDERED
											? t('DealerOrderedFlowBicycleMustHaveADeliveryDate')
											: t('NoActualDeliveryDateGiven'),
									minDate: bicycle.doNotDeliverBeforeDate
										? new Date(bicycle.doNotDeliverBeforeDate)
										: null,
								}}
							/>
						</Box>
					</Box>
					{values.readyForPickup && (
						<Box className={classes.extraFormContainer}>
							<CustomTextInput
								id={'bicycle-detail-cta-ready-pickup-form-frame-number'}
								className={classes.inputBox}
								label={t('BicycleDetailCtaFrameNumberLabel')}
								error={(errors.frameNumber && touched.frameNumber) as boolean}
								helperText={
									(touched.frameNumber && errors.frameNumber) as string
								}
								helperTextId={errors.frameNumber && 'frame-number-helper-text'}
								propsToDelegate={{
									onChange: handleChange,
									value: values.frameNumber,
									name: 'frameNumber',
									disabled:
										bicycle.statusIsSyncing ||
										!isEmptyString(bicycle.frameNumber),
								}}
							/>
							<CustomTextInput
								id={'bicycle-detail-cta-ready-pickup-form-key-number'}
								className={classes.inputBox}
								label={t('BicycleDetailCtaKeyNumberLabel')}
								error={(errors.keyNumber && touched.keyNumber) as boolean}
								helperText={(touched.keyNumber && errors.keyNumber) as string}
								helperTextId={errors.keyNumber && 'key-number-helper-text'}
								propsToDelegate={{
									onChange: handleChange,
									value: values.keyNumber,
									name: 'keyNumber',
									disabled: bicycle.statusIsSyncing,
								}}
							/>
							{bicycle.typeId === speedBikeTypeId && (
								<CustomTextInput
									id={'bicycle-detail-cta-ready-pickup-form-number-plate'}
									className={classNames(classes.inputBox, classes.licenseInput)}
									label={t('BicycleDetailCtaLicensePlateLabel')}
									error={(errors.numberPlate && touched.numberPlate) as boolean}
									helperText={
										(touched.numberPlate && errors.numberPlate) as string
									}
									helperTextId={
										errors.numberPlate && 'number-plate-helper-text'
									}
									propsToDelegate={{
										onChange: handleChange,
										value: values.numberPlate,
										name: 'numberPlate',
										disabled:
											bicycle.statusIsSyncing ||
											!isEmptyString(bicycle.numberPlate),
									}}
								/>
							)}
						</Box>
					)}
					<Box className={classes.buttonsBox}>
						<CustomButton
							id={'bicycle-detail-cta-ready-pickup-form-submit-button'}
							className={classes.updateStatusButton}
							text={
								bicycle.statusIsSyncing
									? t('DealerOrderFlowSyncInProgress')
									: t('BicycleDetailCtaUpdateStatusButton')
							}
							propsToDelegate={{
								type: 'submit',
								disabled:
									loading ||
									bicycle.statusIsSyncing ||
									!dateValuesValid(values),
							}}
						/>
					</Box>
				</form>
			)}
		</Formik>
	)
}

DeliveryDateGivenForm.propTypes = {
	bicycle: PropTypes.any.isRequired,
}

export default DeliveryDateGivenForm
