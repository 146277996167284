import {Box, Typography} from '@material-ui/core'
import classNames from 'classnames'
import React, {useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {RootState} from '@redux/root-reducer'
import {UploadIcon2Svg as UploadIcon} from '@svg/index'
import {LanguageContext} from '@utils/context'
import {userActionTitleCopyKeys} from '@utils/types/user-action'

import useStyles from './style.hook'

const UserActionLog: React.FC = () => {
	const classes = useStyles()

	const userActions = useSelector(
		(state: RootState) => state.bicycleDetail.bicycle?.lastActions
	)

	const {t} = useTranslation(undefined, {useSuspense: false})

	const {activeLanguage} = useContext(LanguageContext)

	const transformDate = (date: string): string =>
		date.split('-').reverse().join('/')

	const getTimeFromTimestamp = (timestamp: number): string => {
		const date = new Date(timestamp)

		return `${date.getHours().toLocaleString(activeLanguage.replace('_', '-'), {
			minimumIntegerDigits: 2,
		})}:${date.getMinutes().toLocaleString(activeLanguage.replace('_', '-'), {
			minimumIntegerDigits: 2,
		})}`
	}

	return userActions?.length > 0 ? (
		<Box className={classNames(classes.cardEffect)}>
			<Typography className={classes.title} variant="h4">
				{t('BicycleDetailUserActionLogTitle')}
			</Typography>
			<Box className={classes.activitiesContainer}>
				{userActions.map((action) => (
					<Box key={action.timestamp} className={classes.activityCard}>
						<Box className={classes.activityType}>
							<UploadIcon />
							<Typography variant="body1">
								{t(userActionTitleCopyKeys.get(action.action)!)}
							</Typography>
						</Box>
						<Typography className={classes.description} variant="h4">
							{action.description}
						</Typography>
						<Typography
							className={classes.dateTime}
							variant="body1"
						>{`${transformDate(action.date)} - ${getTimeFromTimestamp(
							action.timestamp
						)}`}</Typography>
					</Box>
				))}
			</Box>
		</Box>
	) : null
}

export default UserActionLog
