import {Box} from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {orderReset} from '@templates/BicycleDetail/Cta/bicycle-cta-slice'
import {Bicycle, CommonProps} from '@utils/types'
import {BicycleStatus} from '@utils/types/status'

import DeliverForm from './DeliverForm'
import DeliveryDateGivenForm from './DeliveryDateGivenForm'
import OrderedForm from './OrderedForm'
import ReadyForPickupForm from './ReadyForPickupForm'
import StatusIsSyncingPlaceholder from './StatusIsSyncingPlaceholder'
import useStyles from './style.hook'

interface BicycleDetailCtaProps extends CommonProps {
	bicycle: Bicycle
}

interface StatusFormMap {
	[id: string]: React.ReactElement
}

const BicycleDetailCta: React.FC<BicycleDetailCtaProps> = ({bicycle}) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const {bicycleStatus} = bicycle

	const statusForms: StatusFormMap = {
		[BicycleStatus.CYCLIS_ORDERED]: <OrderedForm bicycle={bicycle} />,
		[BicycleStatus.DELIVERY_DATE_GIVEN]: (
			<DeliveryDateGivenForm bicycle={bicycle} />
		),
		[BicycleStatus.DEALER_ORDERED]: <DeliveryDateGivenForm bicycle={bicycle} />,
		[BicycleStatus.READY_FOR_PICKUP]: <ReadyForPickupForm bicycle={bicycle} />,
		[BicycleStatus.PICKUP_INFO_AVAILABLE]: <DeliverForm bicycle={bicycle} />,
	}
	const shownStatuses: string[] = Object.keys(statusForms)

	useEffect(() => {
		return (): void => {
			dispatch(orderReset())
		}
	}, [])

	return (
		<>
			{shownStatuses.includes(bicycleStatus) && (
				<Box
					id={'bicyle-detail-cta-container'}
					className={classNames(classes.cardEffect, classes.ctaContainer)}
				>
					{bicycle.statusIsSyncing ? (
						<StatusIsSyncingPlaceholder bicycle={bicycle} />
					) : (
						statusForms[bicycleStatus]
					)}
				</Box>
			)}
		</>
	)
}

BicycleDetailCta.propTypes = {
	bicycle: PropTypes.any.isRequired,
}

export default BicycleDetailCta
